'use client';

import { AxiosError } from 'axios';
import { useEffect, type PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useSession } from '@/contexts/session-context';
import { ampli } from '@/services/analytics/ampli/Ampli';
import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

const FallbackRender = () => {
   return <div>Loading...</div>;
};

const LoggedInUser = ({ children }: PropsWithChildren) => {
   const { status, data: session } = useSession({ required: true });
   const cognitoId = session?.['cognito:username'];

   useEffect(() => {
      if (!getClientPublicRuntimeConfig().amplitudeApiKey || !cognitoId) return;

      ampli.identify(cognitoId);
   }, [cognitoId]);

   if (status === 'authenticated') {
      return children;
   }

   return null;
};

export const WithLoggedInUser = ({ children }: PropsWithChildren) => {
   return (
      <ErrorBoundary
         onError={(e) => {
            if (e instanceof AxiosError && e.status === 401) {
               return;
            }
            throw e;
         }}
         fallbackRender={FallbackRender}
      >
         <LoggedInUser>{children}</LoggedInUser>
      </ErrorBoundary>
   );
};
