'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { usePatientProgram } from '@/contexts/patient-program-context';

export const ScrollToSection = () => {
   const searchParams = useSearchParams();

   const scrollToSectionId = searchParams.get('section');

   const {
      programPhase: { program },
   } = usePatientProgram();

   useEffect(() => {
      if (program && scrollToSectionId) {
         const element = document.getElementById(scrollToSectionId);

         if (element) {
            window.scrollTo({
               top: element.offsetTop - 20,
               behavior: 'smooth',
            });
         }
      }
   }, [program, scrollToSectionId]);

   return null;
};
