'use client';

import { IntakeProgramPhases } from '@innerwell/dtos';

import { usePatientProgram } from '@/contexts/patient-program-context';
import RejectedRefundStepLayout from '@/layouts/RejectedRefundStepLayout';

export const WithMedicalIntakeGuard = ({
   children,
}: {
   children: React.ReactNode;
}) => {
   const {
      programPhase: { phase },
   } = usePatientProgram();

   if (phase === IntakeProgramPhases.MedicalIntakeRejected) {
      return <RejectedRefundStepLayout />;
   }

   return children;
};
