import { Box, Flex } from '@chakra-ui/react';

interface IProps {
   icon: React.ReactNode;
   text: React.ReactNode;
}

const ExplanationBox: React.FC<IProps> = ({ icon, text }) => {
   return (
      <Flex
         maxW={{ md: '500px' }}
         w="full"
         bg="white"
         boxShadow="md"
         borderRadius="xl"
         py={5}
         pr={{ base: 4, md: 7 }}
      >
         <Flex minW="80px" alignItems="center" justifyContent="center">
            {icon}
         </Flex>
         <Box flexGrow={1}>{text}</Box>
      </Flex>
   );
};

export default ExplanationBox;
