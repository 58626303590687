import { Container, Flex, type FlexProps } from '@chakra-ui/react';

type IProps = FlexProps;

const Layout: React.FC<IProps> = ({ children, ...rest }) => {
   return (
      <Flex
         minH="var(--app-height)"
         w="100%"
         position="relative"
         marginX="auto"
         flexDir="row"
         alignItems="stretch"
         {...rest}
      >
         <Container px={{ base: '0', md: '4' }} maxW="container.xl">
            {children}
         </Container>
      </Flex>
   );
};

export default Layout;
