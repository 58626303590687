'use client';

import { Box } from '@chakra-ui/layout';
import {
   Button,
   Menu,
   MenuButton,
   MenuItemOption,
   MenuList,
   MenuOptionGroup,
   Portal,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDebuggerStore } from './debugger.store';
import { useSearchParams } from 'next/navigation';

export const Debugger = () => {
   const searchParams = useSearchParams();
   const shouldEnableDebuggerFromParams =
      searchParams.get('enable_debug') === '1';

   const isDebuggerEnabled = useDebuggerStore((state) => state.showDebugger);
   const setDebuggerEnabled = useDebuggerStore(
      (state) => state.setShowDebugger,
   );

   const showTestClinicians = useDebuggerStore(
      (state) => state.showTestClinicians,
   );
   const setShowTestClinicians = useDebuggerStore(
      (state) => state.setShowTestClinicians,
   );

   useEffect(() => {
      if (shouldEnableDebuggerFromParams) {
         setDebuggerEnabled(true);
      }
   }, [shouldEnableDebuggerFromParams, setDebuggerEnabled]);

   if (!isDebuggerEnabled) {
      return null;
   }

   return (
      <Portal>
         <Box position="fixed" zIndex={999999} top={2} right={2}>
            <Menu closeOnSelect={false}>
               <MenuButton
                  as={Button}
                  colorScheme="blue"
                  variant="unstyled"
                  size="xs"
                  bg="rgba(255, 255, 255, 0.7)"
                  border="1px solid"
                  borderColor="gray.200"
                  color="text.primary"
                  fontWeight={600}
                  px={2}
                  py={3}
                  minH="none"
                  lineHeight={1}
                  display="flex"
               >
                  Open debugger
               </MenuButton>
               <MenuList minWidth="240px">
                  <MenuOptionGroup
                     title="Scheduling"
                     type="checkbox"
                     value={showTestClinicians ? ['showTestClinicians'] : []}
                     onChange={(value) => {
                        setShowTestClinicians(!!value.length);
                     }}
                  >
                     <MenuItemOption value="showTestClinicians">
                        Show test clinicians
                     </MenuItemOption>
                  </MenuOptionGroup>
               </MenuList>
            </Menu>
         </Box>
      </Portal>
   );
};
