import { useEffect, useRef } from 'react';

type CallbackFn = () => void;

// https://developer.mozilla.org/en-US/docs/Web/API/Window/online_event
const useNetworkStatus = ({
   onOnline,
   onOffline,
}: {
   onOnline?: CallbackFn;
   onOffline?: CallbackFn;
}) => {
   const refOnOnline = useRef<CallbackFn | undefined>(undefined);
   const refOnOffline = useRef<CallbackFn | undefined>(undefined);

   refOnOnline.current = onOnline;
   refOnOffline.current = onOffline;

   useEffect(() => {
      const handleOnline = () => {
         refOnOnline.current?.();
      };

      const handleOffline = () => {
         refOnOffline.current?.();
      };
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);

      return () => {
         window.removeEventListener('online', handleOnline);
         window.removeEventListener('offline', handleOffline);
      };
   }, []);
};

export default useNetworkStatus;
