import { Flex, type FlexProps } from '@chakra-ui/react';

import { InnerwellLogo } from '../Images';

const DefaultHeader = (props: FlexProps) => {
   return (
      <Flex as="header" zIndex={6} pos="relative" p={25} {...props}>
         <InnerwellLogo fill="background.tertiary" />
      </Flex>
   );
};

export default DefaultHeader;
